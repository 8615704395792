import React, { useContext, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { ModalComponent } from "../../Components/Modal";
import { Context } from "../../Shared/Context/GlobalContext";
import { usePaymentMethods } from "../../Shared/Hooks/usePaymentMethods";
import { PaymentFormComponent } from "../CheckoutPage/components/PaymentFormComponent";
import { useNavigate } from "react-router";
import routes from "../../Helpers/Routes.json";
import { useTranslation } from "react-i18next";

function PaymentPage() {
  const [modalOpen, setModalOpen] = useState(false);
  const { paymentMethods } = usePaymentMethods([]);
  const { cartProducts, user } = useContext(Context);
  const navigate = useNavigate();

  const { t } = useTranslation();

  return (
    <Row>
      <h1>{t("Completar el pedido")}</h1>
      <Col xs="12">
        {paymentMethods.map(({ name, slug, description }) => {
          return (
            <Row>
              <Col className="mt-3 text-center">
                <Button
                  size="sm"
                  variant="outline-success"
                  data-method={slug}
                  onClick={(e) => {
                    fetch(process.env.REACT_APP_API_HOST + "/api/payment", {
                      method: "post",
                      headers: {
                        "X-Customer-Token": user.access_token,
                      },
                      body: JSON.stringify({
                        products: cartProducts,
                        payment_method: e.target.dataset.method,
                      }),
                    })
                      .then((res) => {
                        if (res.status === 200) {
                          return res.json();
                        }
                        throw new Error("Something went wrong");
                      })
                      .catch((error) => {
                        console.error(error);
                      })
                      .then(({ data }) => {
                        navigate(
                          routes.Payment.success.replace(
                            ":transaction",
                            data.transaction
                          )
                        );
                      });
                  }}
                >
                  {name}
                </Button>
              </Col>
            </Row>
          );
        })}

        <ModalComponent
          show={modalOpen}
          setShow={setModalOpen}
          title="Payment Form"
        >
          <PaymentFormComponent />
        </ModalComponent>
      </Col>
    </Row>
  );
}

export default PaymentPage;
