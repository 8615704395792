export const Woo = {
  domain: {
    hello: async () => {
      return fetch(process.env.REACT_APP_API_HOST + "/api/hello").then((res) =>
        res.json()
      );
    },
  },
  categories: {
    all: async () => {
      return fetch(
        process.env.REACT_APP_API_HOST + "/api/products/categories"
      ).then((res) => res.json());
      // return WooCommerce.get("products/categories");
    },
  },
  products: {
    all: async () => {
      return fetch(process.env.REACT_APP_API_HOST + "/api/products").then(
        (res) => res.json()
      );
    },
    findByCategory: async (category, options = {}) => {
      return fetch(
        `${process.env.REACT_APP_API_HOST}/api/products${category ? `?category=${category}` : ""
        }`, options
      ).then((res) => res.json());
    },
    findBy: async (criteria) => {
      return fetch(
        `${process.env.REACT_APP_API_HOST}/api/products?` +
        new URLSearchParams(criteria)
      ).then((e) => e.json());
    },
    find: async (slug) => {
      return fetch(
        `${process.env.REACT_APP_API_HOST}/api/product${slug ? `?slug=${slug}` : ""
        }`
      ).then((res) => res.json());
    },
  },
  orders: {
    all: async (customer) => {
      return fetch(process.env.REACT_APP_API_HOST + "/api/orders", {
        headers: { "X-Customer-Token": customer },
      }).then((res) => res.json());
    },
    find: async (customer, id) => {
      return fetch(`${process.env.REACT_APP_API_HOST}/api/orders/${id}`, {
        headers: { "X-Customer-Token": customer },
      }).then((res) => res.json());
    },
  },
  coupons: {
    get: async (code) => {
      return fetch(`${process.env.REACT_APP_API_HOST}/api/coupons?coupon_code=${code}`, {
      }).then((res) => res.json());
    },
  },
  customers: {
    post: async (user) => {
      const data = {
        email: user.email,
        first_name: user.first_name,
        last_name: user.last_name,
        username: user.username,
        password: user.password,
        phone: user.phone,
      };
      return fetch(process.env.REACT_APP_API_HOST + "/api/customers", {
        method: "POST",
        body: JSON.stringify(data),
      });
    },
    put: async (id, user) => {
      return fetch(process.env.REACT_APP_API_HOST + "/api/customers/" + id, {
        method: "PUT",
        body: JSON.stringify(user),
      }).then((data) => data.json());
    },
  },
};

export function createWooProduct(data) {
  return {
    product: {
      id: data.id,
      name: data.name,
      type: data.type,
      bundle: data.bundledItems,
      quantity: data.quantity,
      variation: data.variation,
      price: data.price,
    },
  };
}
