import React from "react";
import { Col, Row } from "react-bootstrap";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import routes from "../../Helpers/Routes.json";
import { useTranslation } from "react-i18next";

function PaymentSuccessPage() {
  const params = useParams();

  const { t } = useTranslation();

  return (
    <>
      <Row>
        <Col xs={12} className="text-center">
          <h2>{t("¡Hemos recibido tu pedido!")}</h2>
          <h3>{params.order}</h3>
          <p>
            {t("Empezaremos a prepararlo enseguida")}{" "}
            <span aria-label="smile" role={"img"}>
              😊
            </span>
          </p>
        </Col>
        <Col xs={6} className="m-auto text-center">
          <Link
            to={routes.Homepage.index}
            className="btn btn-block btn-outline-success"
          >
            {t("Volver")}
          </Link>
        </Col>
      </Row>
      <Row className="text-center">
        <Col>
          <Link
            to={routes.OrdersPage.detail.replace(":order", params.transaction)}
            className="btn btn-block btn-sm btn-outline-secondary mt-3"
          >
            {t("Ver el pedido")}
          </Link>
        </Col>
      </Row>
    </>
  );
}

export default PaymentSuccessPage;
