import React, { createContext, useState } from "react";
import { useLocalStorage } from "../Hooks/useLocalStorage";

export const Context = createContext();

const Provider = ({ children, data }) => {
  const ls = useLocalStorage();
  const [user, setUser] = useState(() => {
    return ls.getJSON("user") ? ls.getJSON("user") : null;
  });
  const [cartProducts, addCartProducts] = useState(() => {
    return ls.getJSON("cartProducts") ? ls.getJSON("cartProducts") : [];
  });
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [categoriesLoading, setCategoriesLoading] = useState(true);
  const [productsLoading, setProductsLoading] = useState(true);
  const [firstRender, isFirstRender] = useState(true);
  const [categories, setCategories] = useState([]);
  const [domain, setDomain] = useState(data);

  const value = {
    user,
    setUser: (data) => {
      setUser(data);
      ls.setJSON("user", data);
    },
    domain,
    setDomain,
    logout: () => {
      setUser(null);
      ls.remove("user");
    },
    addCartProducts: (data) => {

      let updated = false;

      for (let index = 0; index < cartProducts.length; index++) {
        if (JSON.stringify(cartProducts.product) === JSON.stringify(data.product)) {
          updateCart(index, "sum");
          updated = true
        }
      }

      if (false === updated) {
        let newProducts = cartProducts;
        newProducts.push(data);
        addCartProducts(newProducts)
        ls.setJSON('cartProducts', newProducts)
      }
    },
    updateCart: (index, action = "sum") => {
      updateCart(index, action)
    },
    resetCart: () => {
      ls.remove("cartProducts");
      addCartProducts([]);
    },
    removeCartProduct: (product) => {
      const filteredProducts = cartProducts.filter((i, key) => {
        return product !== key;
      });
      addCartProducts(filteredProducts);
      ls.setJSON("cartProducts", filteredProducts);
    },
    cartProducts,
    selectedCategory,
    setSelectedCategory: (category) => {
      setSelectedCategory(category);
    },
    categoriesLoading,
    setCategoriesLoading: (value) => {
      setCategoriesLoading(value);
    },
    productsLoading,
    setProductsLoading: (value) => {
      setProductsLoading(value);
    },
    categories,
    setCategories: (value) => {
      setCategories(value);
    },
    firstRender,
    isFirstRender: (value) => {
      isFirstRender(value);
    },
  };

  function updateCart(index, action = "sum") {
    let newProducts = [...cartProducts];
    const { product } = cartProducts[index];
    let productQty = product.quantity

    if (action === "sum") {
      productQty++;

      if (product.maxStock > productQty || product.type === "grouped") {
        product.quantity = productQty
      };

      newProducts[index] = { product: product }
    }

    if (action === "reduce") {
      productQty--;

      if (productQty <= 0) {
        newProducts = newProducts.filter((i, key) => {
          if (key === index) {
            return false
          }
          return true
        })
      } else {
        product.quantity = productQty;

        newProducts[index] = { product: product }
      }

    }
    addCartProducts(newProducts);
    ls.setJSON('cartProducts', newProducts)
  }
  return <Context.Provider value={value}>{children}</Context.Provider>;
};

export default {
  Provider,
  Consumer: Context.Consumer,
};
