export const useLocalStorage = () => {

    function get(key) {
        return localStorage.getItem(key)
    }

    function set(key, value) {
        localStorage.setItem(key, value)
    }

    function getJSON(key) {

        try {
            const lsVal = localStorage.getItem(key);

            if (lsVal) {
                return JSON.parse(lsVal)

            }
        } catch (error) {
            console.warn(error);
            return null;
        }

    }

    function setJSON(key, value) {
        localStorage.setItem(key, JSON.stringify(value))
    }

    function remove(key) {
        localStorage.removeItem(key)
    }

    return { get, set, getJSON, setJSON, remove }
}