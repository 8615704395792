import React, { useState } from "react";
import { Carousel, Col, Container, Row } from "react-bootstrap";
import { AddToCartButton } from "../../../../../Components/AddToCartComponent";
import { StyledImageProduct } from "../../../style.css";
import { useTranslation } from "react-i18next";
import parse from "html-react-parser";
import { AlertComponent } from "../../../../../Components/AlertComponent";

function SimpleProductLayout({ product }) {
  const { t } = useTranslation();
  const [error, setError] = useState(null);

  return (
    <>
      <Carousel data-bs-theme="dark">
        {product.images.map((i) => {
          return (
            <Carousel.Item key={i.src}>
              <StyledImageProduct src={i.src} className="w-100 d-block" />
            </Carousel.Item>
          );
        })}
      </Carousel>
      <Container className="mt-3">
        <Row>
          <Col>
            <h2>{t(product.name)}</h2>
            <p>{product.price}€</p>
            <div>{parse(t(product.short_description))}</div>
          </Col>
        </Row>
        <Row>
          <Col>
            <AddToCartButton
              originalProduct={product}
              product={product}
              setError={setError}
              validator={() => {
                return { success: true };
              }}
            />
          </Col>
        </Row>
      </Container>
      {error && (
        <Container>
          <AlertComponent
            variant="danger"
            className="fixed-bottom m-auto w-100"
          >
            {error}
          </AlertComponent>
        </Container>
      )}
    </>
  );
}

export default SimpleProductLayout;
