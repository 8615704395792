import React, { useContext } from "react";
import { Row } from "react-bootstrap";
import { useLocation } from "react-router";
import { Context } from "../../Shared/Context/GlobalContext";
import { useProductCategoriesData } from "../../Shared/Hooks/useProductCategoriesData";
import ProdutCategories from "./__partials/ProductCategories";

const Header = () => {
  const { categories } = useProductCategoriesData();
  const { setSelectedCategory, categoriesLoading, productsLoading } =
    useContext(Context);
  const location = useLocation();

  if (categoriesLoading || productsLoading) {
    return null;
  }

  if (location.pathname.startsWith("/product/")) {
    return null;
  }

  return (
    <header className="text-center pt-2">
      <img
        src="https://lacasablancapastisseria.com/wp-content/uploads/elementor/thumbs/LCB__Logo-simple-pk5jkm332qmx0jn3se58mqfy1p8bq5rg19bynmvsbo.png"
        title="LCB__Logo simple"
        alt="LCB__Logo simple"
        loading="lazy"
        style={{
          width: "30%",
        }}
      />
      {location.pathname.startsWith("/product/") ||
      location.pathname.startsWith("/cart") ||
      location.pathname.startsWith("/account") ||
      location.pathname.startsWith("/checkout") ? null : (
        <ProdutCategories
          setSelectedCategory={setSelectedCategory}
          categories={categories}
        />
      )}
    </header>
  );
};

export default Header;
