import React, { useContext, useState } from "react";
import { useEffect } from "react";
import {
  Col,
  Container,
  Form,
  InputGroup,
  Row,
  Spinner,
} from "react-bootstrap";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Context } from "../../Shared/Context/GlobalContext";
import { usePaymentMethods } from "../../Shared/Hooks/usePaymentMethods";
import { paymentInterval } from "../../Shared/Hooks/useProcessingPaymentInterval";
import { useShippingMethods } from "../../Shared/Hooks/useShippingMethods";
import { ShippingFormComponent } from "../CheckoutPage/components/ShippingFormComponent";
import LoginPage from "../LoginPage";
import { CartListComponent } from "./components/CardItemComponent";
import { CashExternalButtonComponent } from "./PaymentPageComponents/CashExternalButtonComponent";
import { RedsysRedirectionButtonComponent } from "./PaymentPageComponents/RedsysRedirectionButtonComponent";
import es from "date-fns/locale/es";
import { addDays } from "date-fns";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { Button } from "react-bootstrap";
import { Woo } from "../../Helpers/Woo";
import { useNavigate } from "react-router";
import routes from "../../Helpers/Routes.json";
import { formatCurrency } from "../../Shared/helpers/currency";
registerLocale("es", es);

function OrderPage() {
  const { cartProducts, user, resetCart, updateCart } = useContext(Context);
  const { paymentMethods } = usePaymentMethods([]);
  const { shippingMethods } = useShippingMethods([]);
  const [date, setDate] = useState(null);
  const [time, setTime] = useState(null);
  const [shippingMethod, setShippingMethod] = useState(null);

  const defaultCouponState = {
    form: "",
    applied: false,
    loading: false,
  };
  const [couponCode, setCouponCode] = useState(defaultCouponState);
  const [coupon, setCoupon] = useState(0);
  const [displayCuponForm, setDisplayCouponForm] = useState(false);
  const [comments, setComments] = useState("");

  const { t } = useTranslation();
  const navigate = useNavigate();

  const days = [
    t("Dom"),
    t("Lun"),
    t("Mar"),
    t("Mier"),
    t("Ju"),
    t("Vie"),
    t("Sab"),
  ];
  const months = [
    t("Enero"),
    t("Febrero"),
    t("Marzo"),
    t("Abril"),
    t("Maryo"),
    t("Junio"),
    t("Julio"),
    t("Agosto"),
    t("Septiembre"),
    t("Octubre"),
    t("Noviembre"),
    t("Diciembre"),
  ];

  const currentDate = new Date();

  useEffect(() => {
    clearInterval(paymentInterval);
    return () => {
      clearInterval(paymentInterval);
    };
  }, []);

  function validateOrder() {
    if (!shippingMethod) {
      return "missing_shipping_method";
    }
    if (shippingMethod.reservationTimes && !getDeliveryDate()) {
      return "missing_shipping_delivery_at";
    }
    return false;
  }

  function handleCouponSubmit(e) {
    setCouponCode({ ...couponCode, loading: true });

    Woo.coupons
      .get(couponCode.form)
      .then(({ data }) => {
        if (!data) {
          setCouponCode(defaultCouponState);
          e.target.focus();
        } else {
          setCoupon({ ...data, amount: parseFloat(data.amount) });
          setCouponCode({ ...couponCode, applied: true, loading: false });
        }
      })
      .catch((e) => {
        console.warn(e);
        setCouponCode(defaultCouponState);
      });
  }

  let price = cartProducts.reduce((carry, item) => {
    if (item.product.type === "bundle" || item.product.type === "grouped") {
      return (
        carry +
        item.product.bundle.reduce((c, i) => {
          if ("variable" === i.product.type) {
            return (
              c +
              parseFloat(i.product.variation.regular_price) *
                parseInt(item.product.quantity)
            );
          } else {
            return (
              c + parseFloat(i.product.price) * parseInt(item.product.quantity)
            );
          }
        }, 0)
      );
    }
    return (
      parseFloat(item.product.price).toFixed(2) *
        parseInt(item.product.quantity) +
      carry
    );
  }, 0);

  const price_with_vat = price;

  if (!user) {
    return <LoginPage doNavigate={false} />;
  }

  if (user && user.billing && !user.billing.first_name) {
    return <ShippingFormComponent />;
  }

  function getDeliveryDate() {
    if (!time || !date) {
      return null;
    }

    return (
      date.toLocaleDateString(i18next.language, {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      }) +
      " " +
      time
    );
  }

  if (coupon) {
    if (coupon.discount_type === "percent") {
      price = parseFloat(
        price - price * (parseInt(coupon.amount) / 100)
      ).toFixed(2);
    } else {
      price = formatCurrency(price - coupon.amount);
    }
  }

  return (
    <Container className="mt-3 mb-5 pb-3">
      <h1>{t("Tu pedido")}</h1>
      {cartProducts.length ? (
        <>
          <CartListComponent
            cartProducts={cartProducts}
            updateCart={updateCart}
          />
          <div className="d-flex flex-column mt-3">
            <div className="small">
              <strong>Total sin IVA:</strong>{" "}
              {formatCurrency(
                price_with_vat - parseFloat(price_with_vat * 0.1)
              )}
            </div>
            <div>
              <strong>{t("Total con IVA")}: </strong>
              {formatCurrency(parseFloat(price_with_vat))}
            </div>
            {coupon ? <div className="small"></div> : null}
            {couponCode.applied ? (
              <div>
                <strong>Cupón:</strong>{" "}
                {coupon.discount_type === "percent" ? (
                  <small>
                    <em>
                      {coupon.amount}% (
                      {formatCurrency(parseFloat(price_with_vat - price))})
                    </em>
                  </small>
                ) : (
                  formatCurrency(parseFloat(coupon.amount))
                )}
                <small>
                  {" "}
                  -<em>{couponCode.form}</em>
                </small>
                <Button
                  variant="danger"
                  size="sm"
                  className="py-0 px-1 ms-2"
                  onClick={() => {
                    setCouponCode(defaultCouponState);
                    setCoupon(null);
                    setDisplayCouponForm(false);
                  }}
                >
                  Eliminar cupón
                </Button>
              </div>
            ) : (
              <>
                <div className="small d-flex">
                  <strong
                    className="btn btn-link btn-sm text-dark d-block py-2 text-left p-0"
                    onClick={() => setDisplayCouponForm(!displayCuponForm)}
                  >
                    {t("¿Tienes un cupón? Click aquí para aplicarlo")}{" "}
                  </strong>
                </div>
                {displayCuponForm ? (
                  <div>
                    <Form.Control
                      value={couponCode.form}
                      onChange={(e) =>
                        setCouponCode({ ...couponCode, form: e.target.value })
                      }
                      placeholder="Introduce el cupón"
                    ></Form.Control>
                    <Button
                      size="sm"
                      className="mt-2 d-flex align-items-center"
                      onClick={handleCouponSubmit}
                      disabled={
                        couponCode.loading ||
                        !couponCode.form ||
                        couponCode.form.length === 0
                      }
                    >
                      {couponCode.loading ? (
                        <Spinner
                          size="sm"
                          animation="border"
                          className="me-2"
                        />
                      ) : null}
                      Aplicar cupón
                    </Button>
                  </div>
                ) : null}
              </>
            )}
          </div>
          <div className="mt-1">
            <strong>{t("Total del pedido")}: </strong>
            {formatCurrency(Math.max(parseFloat(price), 0))}
          </div>

          <hr />
          <div className="form-group">
            <Form.Label htmlFor="comments-input" className="h6">
              {t("Alergias o comentarios")}:
            </Form.Label>
            <InputGroup>
              <Form.Control
                as="textarea"
                id="comments-input"
                aria-label="With textarea"
                value={comments}
                onChange={(e) => setComments(e.target.value)}
              />
            </InputGroup>
          </div>
          <hr />
          <h4 className="h5">{t("Forma de entrega")}</h4>
          {shippingMethods.map(
            ({
              id,
              name,
              description,
              reservationTimes,
              reservationIntervalTime,
              exceptions = [],
            }) => {
              return (
                <Col
                  key={`shipping-${id}`}
                  role={"button"}
                  xs={12}
                  className={`mt-3 border border-2 p-2 rounded ${
                    shippingMethod &&
                    name === shippingMethod.name &&
                    "border-success"
                  }`}
                  onClick={() => {
                    setShippingMethod({ name, reservationTimes });
                  }}
                >
                  {reservationTimes ? (
                    <>
                      <h5>{name}</h5>
                      <p>{description}</p>
                      <small className="text-muted">
                        {t("Selecciona una fecha para la entrega")}
                      </small>
                      <DatePicker
                        className="w-100"
                        customInput={
                          <input
                            className="form-control w-100"
                            inputMode="none"
                          />
                        }
                        locale={{
                          localize: {
                            day: (n) => days[n],
                            month: (n) => months[n],
                          },
                          formatLong: {
                            date: () => "dd/MM/yyyy",
                          },
                        }}
                        selected={date}
                        timeIntervals={
                          reservationIntervalTime ? reservationIntervalTime : 15
                        }
                        timeFormat="HH:mm"
                        onChange={(date) => {
                          setDate(date);
                          setTime(null);
                          setShippingMethod({ name, reservationTimes });
                        }}
                        minDate={addDays(new Date(), 1)}
                        maxDate={addDays(new Date(), 15)}
                        dateFormat={"dd/MM/yyyy"}
                        filterTime={(time) => {
                          const currentDate = new Date();
                          const selectedDate = new Date(time);

                          if (currentDate.getTime() < selectedDate.getTime()) {
                            const weekday = selectedDate
                              .toLocaleDateString("en-EN", {
                                weekday: "long",
                              })
                              .toLowerCase();
                            let hour = selectedDate.toLocaleTimeString(
                              "en-EN",
                              {
                                hour: "2-digit",
                                minute: "2-digit",
                                hour12: false,
                              }
                            );

                            return reservationTimes[weekday].includes(hour);
                          }
                          return false;
                        }}
                        calendarStartDay={1}
                        excludeDates={exceptions.map((i) => new Date(i))}
                      />
                      <CustomTimeInput
                        date={date}
                        reservationTimes={reservationTimes}
                        setTime={setTime}
                      />
                      <p className="mt-3">
                        {t("Fecha Entrega")}:{" "}
                        {getDeliveryDate() ? getDeliveryDate() : "Sin definir"}
                      </p>
                    </>
                  ) : (
                    <>
                      <h5 className="fw-bold">{name}</h5>
                      <p>{description}</p>
                      <Button>{name}</Button>
                    </>
                  )}
                </Col>
              );
            }
          )}
          <hr />
          <h4 className="h5">{t("Forma de pago")}</h4>
          <Row className="pb-5">
            {price <= 0.5 ? (
              <Button
                variant="outline-success"
                size="sm"
                className="rounded rounded-pill mt-3"
                onClick={(e) => {
                  const validationResult = validateOrder();

                  switch (validationResult) {
                    case "missing_shipping_method":
                      alert("Falta seleccionar forma de entrega");
                      return false;

                    case "missing_shipping_delivery_at":
                      alert("Selecciona una fecha de entrega");
                      return false;
                    default:
                  }

                  fetch(process.env.REACT_APP_API_HOST + "/api/payment", {
                    method: "post",
                    headers: {
                      "X-Customer-Token": user.access_token,
                    },
                    body: JSON.stringify({
                      products: cartProducts,
                      coupon_code: couponCode.applied ? couponCode.form : null,
                      shipping_method: shippingMethod,
                      delivery_at: getDeliveryDate(),
                      comments: comments,
                    }),
                  })
                    .then((res) => {
                      if (res.status === 200) {
                        return res.json();
                      }
                      throw new Error("Something went wrong");
                    })
                    .catch((error) => {
                      console.error(error);
                    })
                    .then(({ data }) => {
                      resetCart();
                      navigate(
                        routes.Payment.success.replace(
                          ":transaction",
                          data.transaction
                        )
                      );
                    });
                }}
              >
                Completar pedido
              </Button>
            ) : (
              paymentMethods.map((paymentMethod) => {
                switch (paymentMethod.slug) {
                  case "no-payment-required":
                    return (
                      <CashExternalButtonComponent
                        key={`method-${paymentMethod.id}`}
                        {...paymentMethod}
                        shippingMethod={
                          shippingMethod ? shippingMethod.name : ""
                        }
                        deliveryAt={getDeliveryDate()}
                        validateOrder={validateOrder}
                        cartProducts={cartProducts}
                        couponCode={couponCode.applied ? couponCode.form : null}
                        comments={comments}
                        user={user}
                      />
                    );
                  case "redsys-redirection":
                    return (
                      <RedsysRedirectionButtonComponent
                        key={`method-${paymentMethod.id}`}
                        {...paymentMethod}
                        shippingMethod={
                          shippingMethod ? shippingMethod.name : ""
                        }
                        deliveryAt={getDeliveryDate()}
                        validateOrder={validateOrder}
                        cartProducts={cartProducts}
                        couponCode={couponCode.applied ? couponCode.form : null}
                        comments={comments}
                        user={user}
                      />
                    );
                  default:
                    return null;
                }
              })
            )}
          </Row>
        </>
      ) : (
        <span>{t("Tu carrito está vacío.")}</span>
      )}
    </Container>
  );
}

function CustomTimeInput({ date, reservationTimes, setTime }) {
  const { t } = useTranslation();

  if (!date) {
    return null;
  }
  const weekday = new Date(date)
    .toLocaleDateString("en-EN", {
      weekday: "long",
    })
    .toLowerCase();

  if (date && reservationTimes[weekday].length) {
    let reservationFiltered = reservationTimes[weekday].filter((hour) => {
      const scheduleDate = new Date(
        date.toLocaleDateString("en-EN", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        }) +
          " " +
          hour
      );
      let currentDate = new Date();
      return currentDate < scheduleDate;
    });

    if (reservationFiltered.length) {
      return (
        <select
          className="form-select"
          name="time-input"
          onChange={(e) => {
            if (e.target.value === "") {
              setTime(null);
            } else {
              setTime(e.target.value);
            }
          }}
        >
          <option value={""}>{t("Selecciona una hora")}</option>
          {reservationFiltered.map((hour) => {
            return (
              <option key={`${weekday}-${hour}`} value={hour}>
                {hour}
              </option>
            );
          })}
        </select>
      );
    }
  }

  return (
    <span>{t("No hay horario disponible para esa fecha, prueba otra.")}</span>
  );
}

export default OrderPage;
