import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { LoadingComponent } from "../../Components/Loading";
import { Woo } from "../../Helpers/Woo";
import { ProductPageComponent } from "./ProductPageComponent";
import { StyledBackButton } from "./style.css";
import { useTranslation } from "react-i18next";
import { useLocalStorage } from "../../Shared/Hooks/useLocalStorage";

function ProductPage() {
  const ls = useLocalStorage();

  const [product, setProduct] = useState({});
  const [loading, setLoading] = useState(true);
  const { productId } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    let currentProd = null;
    const lsProducts = ls.getJSON("products");
    if (lsProducts) {
      for (const lsProduct of lsProducts) {
        if (lsProduct.slug === productId) {
          currentProd = lsProduct;
          setProduct(lsProduct);
          setLoading(false);
        }
      }
    }

    if (currentProd === null) {
      Woo.products.find(productId).then((data) => {
        setProduct(data);
        setLoading(false);
      });
    }
  }, []);

  if (loading) {
    return <LoadingComponent />;
  }

  return (
    <div className="mb-5 pb-5">
      <StyledBackButton
        role={"button"}
        className="h6 position-absolute text-decoration-underline px-3 py-2 m-2 bg-white d-block small rounded-pill"
        style={{ zIndex: 2000 }}
        onClick={() => navigate(-1)}
      >
        {t("Volver")}
      </StyledBackButton>
      <ProductPageComponent product={product} />
    </div>
  );
}

export default ProductPage;
