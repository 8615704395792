import { useState } from "react"

export const useInputValue = (defaultValue = '', name) => {
    const [value, setValue] = useState(defaultValue);

    function onChange(e) {
        setValue(e.target.value)
    };

    return { value, onChange, name: name }
}