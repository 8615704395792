import React, { useEffect, useState } from "react";
import { LoadingComponent } from "../../../Components/Loading";
import { Woo } from "../../../Helpers/Woo";

export const DecoratedApp = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);

  useEffect(() => {
    setLoading(true);
    Woo.domain.hello().then((data) => {
      setData(data);
      setLoading(false);
    });
  }, []);

  if (loading) {
    return <LoadingComponent />;
  }

  return children(data);
};
