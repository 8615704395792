import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
    a {
        text-decoration: none;
        color: initial;
    }

    html, body {
        max-width: 100vw;
        width: 600px;
        margin:0 auto;
        font-family: 'Red Hat Text', sans-serif;
        -webkit-user-select: none; /* Safari */
        -ms-user-select: none; /* IE 10 and IE 11 */
        user-select: none; /* Standard syntax */
    }

    #product-desc * {
        max-width: 100%;
    }
`;
