import { Form } from "react-bootstrap";

export const getQuestionComponents = (
  productQuestions,
  answers,
  setAnswers
) => {
  const questionsArray = [];

  for (let index = 0; index < productQuestions.length; index++) {
    questionsArray.push(
      getQuestionInput(productQuestions[index], index, answers, setAnswers)
    );
  }

  return questionsArray;
};

function getQuestionInput(question, index, answers, setAnswers) {
  switch (question.type) {
    case "text":
      return (
        <Form.Group>
          <Form.Label>
            {question.label}{" "}
            {question.mandatory ? (
              <span className="text-danger">*</span>
            ) : (
              <small className="text-small">(Opcional)</small>
            )}
          </Form.Label>
          <Form.Control
            placeholder={question.placeholder}
            required={question.mandatory}
            onChange={(e) => {
              setAnswers({
                ...answers,
                [question.label]: e.target.value,
              });
            }}
          />
          {question.description ? (
            <small className="text-muted">{question.description}</small>
          ) : null}
        </Form.Group>
      );
    default:
      break;
  }
}
