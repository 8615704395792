import { useContext, useEffect, useState } from "react";
import { Woo } from "../../Helpers/Woo";
import { Context } from "../Context/GlobalContext";

export const useOrders = (intialValue, id) => {
  const [orders, setOrders] = useState(intialValue);
  const [loading, setLoading] = useState(true);
  const { user } = useContext(Context);

  useEffect(() => {
    if (id) {
      Woo.orders.find(user.access_token, id).then(({ data }) => {
        setOrders(data);
        setLoading(false);
      });
    } else {
      Woo.orders.all(user.access_token).then(({ data }) => {
        setOrders(data);
        setLoading(false);
      });
    }
  }, []);

  return { orders, loading };
};
