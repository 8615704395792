import { pulse } from "react-animations";
import styled, { css, keyframes } from "styled-components";

const pulseAnimation = keyframes`
${pulse}
`;

export const textInOut = ({ time = "1s", type = "ease", duration = "infinite" } = {}) => css`
  animation: ${time} ${textInOutKeyframes} ${type} ${duration};
`;

const textInOutKeyframes = keyframes`
  0% {
    opacity: 0;
    transform: translate(-90px, 0);
  }

  15% {
    opacity: 1;
    transform: translate(0px, 0);
  }

  85% {
    opacity: 1;
    transform: translate(0px, 0);
  }

  100% {
    opacity: 0;
    transform: translate(90px, 0);
  }
`;




export const StyledLoadingOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 2000;
`;

export const StyledIconWrapper = styled.div`
  text-align: center;
  & div {
    animation: 1s ${pulseAnimation} infinite;
  }

  & span {
    display: inline-block;
    ${textInOut({time: "3s"})};
  }
`;
