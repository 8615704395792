import React, { useEffect } from "react";
import { Modal } from "react-bootstrap";
import { createPortal } from "react-dom";

export const ModalComponent = ({
  title = "Default Title",
  children,
  show,
  setShow,
  callback = null,
  modalProps = {},
}) => {
  const el = document.getElementById("modal");
  const element = document.createElement("div");

  const handleClose = () => {
    if (callback) {
      callback();
    }
    setShow(false);
  };

  if (!modalProps.modal) {
    modalProps.modal = { centered: true };
  }

  useEffect(() => {
    el.appendChild(element);
    return () => {
      el.removeChild(element);
    };
  }, []);
  return createPortal(
    <Modal show={show} onHide={handleClose} {...modalProps.modal}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{children}</Modal.Body>
    </Modal>,
    el
  );
};
