import React, { useState } from "react";
import { useContext } from "react";
import { Alert, Button, Form, InputGroup, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router";
import { Woo } from "../../../../Helpers/Woo";
import { Context } from "../../../../Shared/Context/GlobalContext";
import { useInputValue } from "../../../../Shared/Hooks/useInputValue";
import routes from "../../../../Helpers/Routes.json";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { Trans, useTranslation } from "react-i18next";

export const CreateCustomerFormComponent = ({ isRegister }) => {
  const { setUser } = useContext(Context);
  const email = useInputValue("", "email");
  const repeatEmail = useInputValue("", "repeat-email");
  const username = useInputValue("", "username");
  const password = useInputValue("", "password");
  const phone = useInputValue("", "email");
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const { t } = useTranslation();

  const navigate = useNavigate();

  function createCustomerPost(e) {
    e.preventDefault();
    if (email.value !== repeatEmail.value) {
      setError(t("Los emails no coinciden"));
      setLoading(false);
      return;
    }

    setLoading(true);
    Woo.customers
      .post({
        email: email.value,
        username: email.value,
        password: password.value,
        phone: phone.value,
      })
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        if (res.error) {
          setError(res.error);
        } else {
          setUser(res.data);
        }
        setLoading(false);
      });
  }

  return (
    <>
      <h1>{t("Registrarse")}</h1>
      {error && <Alert variant="danger">{error}</Alert>}
      <Form onSubmit={createCustomerPost} className="mb-2">
        <Form.Group className="mb-2">
          <Form.Label>{t("Email")}</Form.Label>
          <Form.Control
            type="email"
            className=""
            {...email}
            required
            autoComplete="email"
          />
        </Form.Group>
        <Form.Group className="mb-2">
          <Form.Label>{t("Confirma Email")}</Form.Label>
          <Form.Control
            type="email"
            className=""
            {...repeatEmail}
            required
            autoComplete="email"
          />
        </Form.Group>
        <Form.Group className="mb-2">
          <Form.Label>{t("Teléfono")}</Form.Label>
          <Form.Control
            type="tel"
            className=""
            {...phone}
            required
            autoComplete="tel"
            minLength="9"
            maxLength="14"
          />
        </Form.Group>
        <Form.Group className="mt-3">
          <Form.Label>{t("Contraseña")}</Form.Label>
          <InputGroup>
            <Form.Control
              required
              {...password}
              type={`${showPassword ? "text" : "password"}`}
              minLength="6"
              autoComplete="new-password"
            />
            <InputGroup.Text
              className="bg-light"
              onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword ? <AiOutlineEye /> : <AiOutlineEyeInvisible />}
            </InputGroup.Text>
          </InputGroup>
        </Form.Group>
        <Form.Group className="position-relative mb-3">
          <Form.Check
            required
            id="terms-check"
            name="terms"
            className="mt-3"
            label={
              <Trans
                i18nKey={
                  "Estoy de acuerdo con la <PrivacyLink>Política de privacidad</PrivacyLink> y los <TermsLink>Términos y condiciones</TermsLink> de La Casa Blanca Pastisseria."
                }
                components={{
                  PrivacyLink: (
                    //eslint-disable-next-line
                    <a
                      href="https://lacasablancapastisseria.com/politica-de-privacidad/"
                      target={"_blank"}
                      rel="noreferrer"
                      className="text-decoration-underline"
                    />
                  ),
                  TermsLink: (
                    //eslint-disable-next-line
                    <a
                      href="https://lacasablancapastisseria.com/terminos-de-uso/"
                      target={"_blank"}
                      rel="noreferrer"
                      className="text-decoration-underline"
                    />
                  ),
                }}
              />
            }
          />
        </Form.Group>
        <Button
          type="submit"
          variant="outline-success"
          className="d-flex align-items-center mt-3"
          disabled={loading}
        >
          {loading && (
            <Spinner animation="border" style={{ marginRight: "10px" }} />
          )}
          <div className="pl-3">{t("Registrarse")}</div>
        </Button>
      </Form>
      <hr />
      <small>
        {t("Ya tengo una cuenta")}{" "}
        <strong
          role={"button"}
          className="btn btn-sm btn-outline-success"
          onClick={() => {
            if (isRegister) {
              isRegister(false);
            } else {
              navigate(routes.LoginPage.index);
            }
          }}
        >
          {t("Iniciar sesión")}
        </strong>
      </small>
    </>
  );
};
