import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import routes from "../Helpers/Routes.json";
import Context from "../Shared/Context/GlobalContext";
import { Layout } from "../Shared/Layout";
import AccountPage from "./AccountPage";
import OrdersPage from "./OrdersPage";
import CheckoutPage from "./CheckoutPage";
import Homepage from "./Homepage";
import LoginPage from "./LoginPage";
import OrderPage from "./Orderpage";
import PaymentPage from "./PaymentPage";
import ProductPage from "./ProductPage";
import PaymentSuccessPage from "./PaymentDeclinedPage";
import OrdersDetailPage from "./OrdersDetailPage";
import { ProcessingPaymentPage } from "./ProcessingPayment";
import { TranslatedApp } from "../Assets/HOC/TranslatedApp";

function App({ data }) {
  // CLOSED MESSAGE

  /*  return (
    <StyledLoadingOverlay className="text-center d-flex align-items-center justify-content-center">
      <HiOutlineHome
        className="border border-1 p-3 border-dark rounded-circle mb-4"
        size={80}
      />{" "}
      <h1>¡Estamos de vacaciones!</h1>
      <h3>Abriremos pronto</h3>
    </StyledLoadingOverlay>
  ); */
  return (
    <Context.Provider data={data}>
      <TranslatedApp resources={data.translations}>
        <BrowserRouter>
          <Layout>
            <Routes>
              <Route exact path={routes.Homepage.index}>
                <Route index element={<Homepage />} />
                <Route path={routes.Homepage.category} element={<Homepage />} />
              </Route>
              <Route path={routes.ProductPage.get} element={<ProductPage />} />
              <Route path={routes.OrderPage.index} element={<OrderPage />} />
              <Route
                path={routes.CheckoutPage.index}
                element={<CheckoutPage />}
              />
              <Route path={routes.Payment.index} element={<PaymentPage />} />
              <Route
                path={routes.Payment.success}
                element={<PaymentSuccessPage />}
              />
              <Route
                path={routes.Payment.processing}
                element={<ProcessingPaymentPage />}
              />
              <Route
                path={routes.AccountPage.index}
                element={<AccountPage />}
              />
              <Route path={routes.OrdersPage.index} element={<OrdersPage />} />
              <Route
                path={routes.OrdersPage.detail}
                element={<OrdersDetailPage />}
              />
              <Route path={routes.LoginPage.index} element={<LoginPage />} />
            </Routes>
          </Layout>
        </BrowserRouter>
      </TranslatedApp>
    </Context.Provider>
  );
}

export default App;
