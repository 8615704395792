import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import es from "./Shared/translations/es-ES.json";
import ca from "./Shared/translations/es-ES.json";
import LanguageDetector from 'i18next-browser-languagedetector';


const resources = {
  "es-ES": {
    translations: es,
  },
  "ca-ES": {
    translations: ca,
  },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next) // bind react-i18next to the instance
  .init({
    resources,
    partialBundledLanguages: true,
    ns: [],
    debug: false,
    lng: "es-ES",
    interpolation: {
      escapeValue: false, // not needed for react!!
    },


  });

export default i18n;
