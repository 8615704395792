import { Alert } from "react-bootstrap";
import styled from "styled-components";
import { fadeIn } from "../../Shared/animations/fadeIn";

export const StyledAlert = styled(Alert)`
  ${fadeIn({ time: "0.5s" })}
  position: fixed;
  bottom: 130px;
  max-width: 90vw;
`;
