import React from "react";
import { Col, Container, Row, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import { LoadingComponent } from "../../Components/Loading";
import routes from "../../Helpers/Routes.json";
import { useOrders } from "../../Shared/Hooks/useOrders";
import { useTranslation } from "react-i18next";
import { formatCurrency } from "../../Shared/helpers/currency";

function OrdersPage() {
  const { orders, loading } = useOrders([]);
  const { t } = useTranslation();

  const wooStatus = {
    pending: t("Pendiente de cobro"),
    processing: t("Procesando"),
    "on-hold": t("En espera"),
    completed: t("Completado"),
    cancelled: t("Cancelado"),
    refunded: t("Reembolsado"),
    failed: t("Fallido"),
  };

  return (
    <Container className="mt-3">
      <Row>
        <h1>{t("Mis pedidos")}</h1>
        <Col>
          {loading ? (
            <LoadingComponent />
          ) : orders.length ? (
            <Table className="table-responsive table-striped">
              <thead>
                <tr>
                  <th>{t("Pedido")}</th>
                  <th>{t("Fecha")}</th>
                  <th>{t("Estado")}</th>
                  <th>{t("Total")}</th>
                </tr>
              </thead>
              <tbody>
                {orders.map((order) => {
                  return (
                    <tr className="position-relative">
                      <td>
                        <Link
                          to={routes.OrdersPage.detail.replace(
                            ":order",
                            order.id
                          )}
                          className="stretched-link"
                        >
                          {order.id}
                        </Link>
                      </td>
                      <td>
                        {new Date(order.date_created).toLocaleDateString()}
                      </td>
                      <td>{wooStatus[order.status]}</td>
                      <td>{formatCurrency(parseFloat(order.total))}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          ) : (
            <span>{t("Aún no has hecho ningún pedido.")}</span>
          )}
        </Col>
      </Row>
    </Container>
  );
}

export default OrdersPage;
