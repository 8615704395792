import styled from "styled-components";

export const StyledProductTitle = styled.h3`
  font-size: 0.85rem;
  font-weight: 600;
  text-align: center;
  margin: 2px ;
`;

export const StyledProductPriceLabel = styled.small`
  font-size: 0.8em;
`
