import { useContext, useEffect, useState } from "react";
import { Woo } from "../../Helpers/Woo";
import { Context } from "../Context/GlobalContext";

export function useProductCategoriesData() {
  const {
    categoriesLoading,
    setCategoriesLoading,
    setSelectedCategory,
    categories,
    setCategories,
  } = useContext(Context);
  const [error, setError] = useState(false);

  useEffect(() => {
    setCategoriesLoading(true);
    Woo.categories
      .all()
      .then((data) => {
        setCategories(data);
        setCategoriesLoading(false);
        setSelectedCategory(data.sort((a, b) => a.menu_order < b.menu_order ? -1 : 1)[0]);
      })
      .catch((e) => {
        setError(e);
      });
  }, []);

  return { categories, categoriesLoading, error };
}
