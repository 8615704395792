import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Woo } from "../../Helpers/Woo";
import { Context } from "../Context/GlobalContext";
import { useLocalStorage } from "./useLocalStorage";

export const useProductsData = (id = "default") => {
  const ls = useLocalStorage();
  const [products, setProducts] = useState(() => {
    return ls.getJSON('products') ? ls.getJSON('products') : []
  });
  const [error, setError] = useState(false);
  const { productsLoading, setProductsLoading, categoriesLoading } = useContext(
    Context
  );
  const params = useParams()

  function handleProducts(productsData) {

    const currentProducts = [];
    const lsProducts = ls.getJSON('products')
    const addProducts = [];

    if (lsProducts) {
      for (const prod of lsProducts) {
        currentProducts[prod.id] = prod;
      }
    }

    const url = window.location.pathname;

    for (const productData of productsData) {
      currentProducts[productData.id] = productData;
      if (productData.categories.some(c => url.includes(c.slug))) {
        addProducts.push(productData)
      }
    }

    const productsArray = Object.values(currentProducts);

    ls.setJSON("products", productsArray)


    if (addProducts.length) {
      setProducts(addProducts);
    }

  }




  useEffect(() => {
    if (false === categoriesLoading) {
      const currentProducts = ls.getJSON('products');
      if (!currentProducts) {
        setProductsLoading(true);
      } else {
        let categoryProducts = currentProducts.filter(i => {
          if (!id) {
            return true
          }
          return i.categories.some(c => c.id === id)
        })
        setProducts(categoryProducts.sort(function (a, b) {
          // Turn your strings into dates, and then subtract them
          // to get a value that is either negative, positive, or zero.
          return new Date(b.date_created) - new Date(a.date_created);
        }));
        setProductsLoading(false)
      }

      switch (id) {
        case "default":
          Woo.products
            .all()
            .then((products) => {
              setProductsLoading(false);
              handleProducts(products)
            })
            .catch((e) => {
              setError(e);
            });
          break;
        default:
          Woo.products
            .findByCategory(id)
            .then((products) => {
              setProductsLoading(false);
              handleProducts(products)
            })
            .catch((e) => {
              setError(e);
              setProductsLoading(false);
            });
      }
    }
  }, [id, categoriesLoading]);

  return { products, productsLoading, error };
};
