import { useEffect, useState } from "react";

export const useShippingMethods = (initialValue) => {
  const [shippingMethods, setShippingMethods] = useState(initialValue);

  useEffect(() => {
    fetch(process.env.REACT_APP_API_HOST + "/api/shipping-methods")
      .then((res) => res.json())
      .then((data) => setShippingMethods(data.data));
  }, []);

  return { shippingMethods };
};
