import React, { useEffect } from "react";
import { useParams } from "react-router";
import { LoadingComponent } from "../../Components/Loading";
import {
  paymentInterval,
  processPaymentInterval,
} from "../../Shared/Hooks/useProcessingPaymentInterval";

export const ProcessingPaymentPage = () => {
  const params = useParams();

  useEffect(() => {
    processPaymentInterval(params.transation);
    return () => {
      clearInterval(paymentInterval);
    };
  }, []);
  return <LoadingComponent />;
};
