import React, { useContext, useState } from "react";
import { Button, Row, Col } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { Context } from "../../Shared/Context/GlobalContext";
import routes from "../../Helpers/Routes.json";
import { Trans, useTranslation } from "react-i18next";
import { getQuestionComponents } from "../../Helpers/productHelper";

export const AddToCartButton = ({
  originalProduct,
  product,
  bundledItems,
  afterBehaviour,
  validator,
  setError,
}) => {
  const [quantity, setQuantity] = useState(1);
  const [answers, setAnswers] = useState([]);
  const { addCartProducts, cartProducts } = useContext(Context);
  const { t } = useTranslation();
  const params = useParams();
  const navigate = useNavigate();

  let maxQty = Math.max(1, product.stock_quantity);

  const productQuestions = originalProduct.meta_data.find(
    (i) => i.key === "twc_product_questions"
  );

  const productQuestionsTitle = originalProduct.meta_data.find(
    (i) => i.key === "twc_product_questions_title"
  );

  let productQuestionObject = null;

  const questionsComponents = [];

  if (productQuestions) {
    productQuestionObject = JSON.parse(productQuestions.value);
    questionsComponents.push(
      getQuestionComponents(productQuestionObject, answers, setAnswers)
    );
  }

  const handleAddToCart = () => {
    const cartProduct = {
      product: {
        id: product.id,
        name: product.name,
        type: product.type,
        bundle: bundledItems,
        quantity: quantity,
        variation: product.variation,
        price: product.price,
        maxStock: maxQty
      },
    };

    const valid = validator(product, cartProduct);

    if (productQuestionObject) {
      for (const productQuestion of productQuestionObject) {
        if (!answers[productQuestion.label]) {
          valid.success = false;
          if (valid.error) {
            valid.error += "Falta seleccionar " + productQuestion.label;
          } else {
            valid.error = " Falta seleccionar " + productQuestion.label;
          }
        }
      }
    }

    if (false === valid.success) {
      setError(valid.error);
      setTimeout(() => {
        ///    setError(null);
      }, 2 * 1000);
    }

    if (valid.success) {
      const data = cartProduct;

      if (answers) {
        for (const key of Object.keys(answers)) {
          const answer = {
            question: key,
            value: answers[key],
          };

          if (!data.product.answers) {
            data.product.answers = [answer];
          } else {
            data.product.answers.push(answer);
          }
        }
      }
      
      addCartProducts({ ...data });
      
      if (afterBehaviour) {
        afterBehaviour();
      } else {
        return navigate(
          routes.Homepage.category.replace(":category", params.category),
          {
            state: {
              product: t(product.name),
              variant: "light",
            },
          }
        );
      }
    }
  };

  let hasStock = true;

  if (product.manage_stock === false) {
    hasStock = true;
  } else {
    if (product.stock_quantity > 0) {
      let cartProduct = cartProducts.find((i) => {
        if (i.product.id === product.id) {
          return true;
        }
        return false;
      });

      if (cartProduct) {
        if (cartProduct.product.quantity < product.stock_quantity) {
          hasStock = true;
        } else {
          hasStock = false;
        }
      } else {
        hasStock = true;
      }
    } else {
      hasStock = false;
    }
  }

  return (
    <>
      {questionsComponents.length ? (
        <>
          <h3>
            {productQuestionsTitle ? productQuestionsTitle.value : "Preguntas"}
          </h3>
          <Row className="mb-5">{questionsComponents}</Row>
        </>
      ) : null}
      <Row className="m-auto">
        <Col xs="12" className="text-center">
          <Button
            variant="success"
            className="rounded-left"
            size="lg"
            disabled={product.stock_status !== "instock"}
            onClick={() => {
              if (quantity > 1) {
                setQuantity(quantity - 1);
              }
            }}
          >
            -
          </Button>
          <span className="p-4">{quantity}</span>
          <Button
            size="lg"
            className="rounded-right"
            variant="success"
            disabled={!hasStock}
            onClick={() => {
              if (quantity < maxQty) {
                setQuantity(quantity + 1);
              }
            }}
          >
            +
          </Button>
        </Col>
        <Button
          size="lg"
          variant="outline-success"
          className="mt-3 w-100 mb-3"
          disabled={!hasStock}
          onClick={() => {
            handleAddToCart();
          }}
        >
          {JSON.stringify(product) === "{}" ? (
            t("Selecciona una opción")
          ) : (
            <>
              {hasStock ? (
                <Trans
                  i18nKey={"Añadir {{quantity}} al carrito"}
                  values={{ quantity: quantity }}
                />
              ) : (
                t(`Agotado`)
              )}
            </>
          )}
        </Button>
      </Row>
    </>
  );
};
