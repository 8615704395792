import React from "react";
import { Row, Col, Table } from "react-bootstrap";
import { useParams } from "react-router";
import { LoadingComponent } from "../../Components/Loading";
import { useOrders } from "../../Shared/Hooks/useOrders";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { formatCurrency } from "../../Shared/helpers/currency";

function OrdersDetailPage() {
  const params = useParams();
  const { orders, loading } = useOrders({}, params.order);

  const { t } = useTranslation();

  if (loading) {
    return <LoadingComponent />;
  }

  const metadata = orders.meta_data.filter((i) => {
    return i.key === "delivery_at";
  });

  const wooStatus = {
    pending: t("Pendiente de cobro"),
    processing: t("Procesando"),
    "on-hold": t("En espera"),
    completed: t("Completado"),
    cancelled: t("Cancelado"),
    refunded: t("Reembolsado"),
    failed: t("Fallido"),
  };

  return (
    <Row>
      <Col xs={12}>
        <h1>
          {"Pedido"} #{orders.id}
        </h1>
        <div className=" mb-3">
          <small className="d-block mt-0">
            {t("Fecha de pedido")}:{" "}
            {new Date(orders.date_created).toLocaleDateString(
              i18next.language,
              {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
                hour: "2-digit",
                minute: "2-digit",
              }
            )}
          </small>
          {metadata && metadata[0] ? (
            <small>
              {t("Entrega")}: {metadata[0].value}
            </small>
          ) : null}
          <p>
            {t("Forma de pago")}:{" "}
            {parseFloat(orders.total) < 0.5
              ? "Descuentos"
              : orders.payment_method}
          </p>
          <p>
            {t("Estado")}: {wooStatus[orders.status]}
          </p>
        </div>
        {orders.needs_payment ? (
          <span className="bg-warning p-1 px-2 rounded">
            {t("Pendiente de cobro")}
          </span>
        ) : (
          <span className="bg-success text-white p-1 px-2 rounded">Pagado</span>
        )}
      </Col>
      <Col xs={12} className="mt-3">
        <Table className="table-responsive table-striped">
          <thead>
            <tr>
              <th>{t("Producto")}</th>
              <th>{t("Cantidad")}</th>
              <th>{t("Total con IVA")}</th>
            </tr>
          </thead>
          <tbody>
            {orders.line_items.map(
              ({ id, name, price, quantity, subtotal_tax }) => {
                return (
                  <tr key={`product-${id}`}>
                    <td>{t(name)}</td>
                    <td>{quantity}</td>
                    <td>
                      {formatCurrency(
                        parseFloat(price * quantity) + parseFloat(subtotal_tax)
                      )}
                    </td>
                  </tr>
                );
              }
            )}
          </tbody>
        </Table>
        <div>
          <strong>Descuentos:</strong>{" "}
          {formatCurrency(parseFloat(orders.discount_total))}
        </div>
        <div className="mt-2">
          <span>
            <strong>{t("Total")}:</strong> {formatCurrency(orders.total)}
          </span>
        </div>
      </Col>
    </Row>
  );
}

export default OrdersDetailPage;
