import React from "react";
import { StyledAlert } from "./style.css";

export const AlertComponent = ({ variant, children }) => {
  return (
    <StyledAlert variant={variant} className="text-center">
      {children}
    </StyledAlert>
  );
};
