import React, { useContext } from "react";
import { AiOutlineUser } from "react-icons/ai";
import { HiOutlineHome } from "react-icons/hi";
import { GrCart } from "react-icons/gr";
import { NavLink } from "react-router-dom";
import routes from "../../Helpers/Routes.json";
import { Context } from "../../Shared/Context/GlobalContext";
import { StyledFooter } from "./style.css";

export const Footer = () => {
  const { user, cartProducts } = useContext(Context);

  return (
    <StyledFooter className="footer fixed-bottom d-flex justify-content-around align-items-center p-4 bg-light">
      <NavLink
        to={user ? routes.AccountPage.index : routes.LoginPage.index}
        className="rounded-circle d-flex align-items-center border-dark"
      >
        <AiOutlineUser size={18} color="dark" />
      </NavLink>

      <NavLink
        to={routes.Homepage.index}
        className="rounded-circle d-flex align-items-center border-dark"
      >
        <HiOutlineHome size={24} color="dark" />
      </NavLink>

      <NavLink
        to={routes.OrderPage.index}
        className="rounded-circle p-1 d-flex align-items-center border-dark"
      >
        <GrCart size={18} color="dark" />
        {cartProducts.length && (
          <span
            className="bg-outline-primary position-absolute text-dark small "
            style={{
              bottom: "25px",
              transform: "translateX(20px)",
            }}
          >
            {cartProducts.length}
          </span>
        )}
      </NavLink>
    </StyledFooter>
  );
};
