import React from "react";
import { GroupedProductLayout } from "./ProductLayouts/GroupedProductLayout";
import SimpleProductLayout from "./ProductLayouts/SimpleProductLayout";
import { VariableProductPage } from "./ProductLayouts/VariableProductLayout";

export const ProductPageComponent = ({ product }) => {
  switch (product.type) {
    case "grouped":
      return <GroupedProductLayout product={product} />;
    case "variable":
      return <VariableProductPage product={product} />;
    case "simple":
      return <SimpleProductLayout product={product} />;
    default:
      return null;
  }
};
