import React from "react";
import { Card, Col } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { StyledProductPriceLabel, StyledProductTitle } from "./style.css";
import routes from "../../../Helpers/Routes.json";
import { useTranslation } from "react-i18next";
import { LoadingComponent } from "../../Loading";
import { useProductsData } from "../../../Shared/Hooks/useProductsData";
import { formatCurrency } from "../../../Helpers/currency";

export const ProductsGrid = ({ category, selectedCategory }) => {
  const { products } = useProductsData(
    category ? category.id : selectedCategory ? selectedCategory.id : null
  );

  if (!products.length) {
    return <LoadingComponent />;
  }

  return products.map((product) => {
    if (
      product.catalog_visibility !== "visible" ||
      product.status !== "publish"
    ) {
      return null;
    }

    return <ProductCard key={`product-${product.id}`} {...product} />;
  });
};

export const ProductCard = ({
  id,
  slug,
  name,
  price,
  images,
  type,
  stock_status,
  product_variations,
  link = true,
  opacity = true,
  as,
  cardClickCallback = () => true,
  customFooter = null,
  textPrice = null,
  customCSS,
  cardCloseBtn,
  stock_quantity,
}) => {
  const { t } = useTranslation();
  const params = useParams();

  const img = 1 <= images.length ? images[0] : {};
  let price_label = formatCurrency(price);

  if (product_variations) {
    if (product_variations.length) {
      let prices = product_variations.map((variation) => {
        if (variation.sales_price) {
          return parseFloat(variation.sales_price);
        }
        return parseFloat(variation.regular_price);
      });

      price_label = "Desde " + formatCurrency(Math.min(...prices));
    }
  }

  if ("grouped" === type) {
    price_label = "";
  }

  let WrapperLink = Link;
  /*   if (stock_status === "outofstock") {
    WrapperLink = React.Fragment;
  } */

  if (as) {
    WrapperLink = as;
  }

  return (
    <Col
      key={id}
      lg="6"
      sm="6"
      xs="6"
      className="mb-4"
      onClick={cardClickCallback}
    >
      <WrapperLink
        key={id}
        to={routes.ProductPage.get
          .replace(":category", params.category)
          .replace(":productId", slug)}
        className={`colot-initial text-decoration-none ${
          link === false && opacity ? "opacity-80" : ""
        }`}
      >
        <Card className={`border-0 px-1 position-relative`}>
          <span className="d-inline-flex align-self-end me-2 p-0 position-absolute">
            {cardCloseBtn}
          </span>
          <div
            onClick={cardClickCallback}
            className={`d-flex flex-column justify-content-center align-items-center ${
              stock_status === "instock" ? "" : "opacity-75"
            } ${customCSS ? customCSS : ""}`}
          >
            <Card.Img
              className="m-auto text-center w-100"
              alt={img.alt}
              src={img.src}
            />
            <Card.Body
              className={`py-0 px-1 pt-2 ${
                link === false && opacity ? "opacity-25" : ""
              }`}
            >
              <StyledProductTitle>
                <span className="d-block">{t(name)}</span>
              </StyledProductTitle>
              <div className="text-center d-block">
                <StyledProductPriceLabel>
                  {stock_status === "instock"
                    ? textPrice
                      ? textPrice
                      : price_label
                    : "Agotado"}
                </StyledProductPriceLabel>
              </div>
              {stock_quantity > 0 && stock_quantity < 3 ? (
                <div className="text-end bottom-0 mt-2">
                  <em>
                    <StyledProductPriceLabel>
                      Quedán {stock_quantity}
                    </StyledProductPriceLabel>
                  </em>
                </div>
              ) : null}
              {customFooter}
            </Card.Body>
          </div>
        </Card>
      </WrapperLink>
    </Col>
  );
};
