import React from "react";
import { Footer } from "../../Components/Footer";
import Header from "../../Components/Header";
import { Container } from "react-bootstrap";

export const Layout = ({ children, loading, setSelectedCategory }) => {
  return (
    <>
      <Header setSelectedCategory={setSelectedCategory} />
      {loading ? <div>loading</div> : children}
      <Footer />
    </>
  );
};
