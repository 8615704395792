import React, { useContext, useState } from "react";
import { Card, Carousel, Col, Container, Row } from "react-bootstrap";
import { AddToCartButton } from "../../../../../Components/AddToCartComponent";
import { AlertComponent } from "../../../../../Components/AlertComponent";
import { StyledProductTitle } from "../../../../../Components/Products/Grid/style.css";
import { variableProductValidator } from "../../../../../Helpers/validator";
import { Context } from "../../../../../Shared/Context/GlobalContext";
import { StyledImageProduct } from "../../../style.css";
import { useTranslation } from "react-i18next";
import parse from "html-react-parser";

export const VariableProductPage = ({
  product,
  setSelectedVariation,
  setShow,
  isModal = false,
}) => {
  const [selectedProduct, setSelectedProduct] = useState({});
  const [error, setError] = useState(null);
  const { cartProducts } = useContext(Context);

  const { t } = useTranslation();

  return (
    <>
      {isModal === false && (
        <>
          <Carousel data-bs-theme="dark">
            {product.images.map((i) => {
              return (
                <Carousel.Item key={i.src}>
                  <StyledImageProduct src={i.src} className="w-100 d-block" />
                </Carousel.Item>
              );
            })}
          </Carousel>
        </>
      )}
      <Container>
        {isModal === false ? (
          <h2 className="mt-3 fw-bold">{t(product.name)}</h2>
        ) : null}
        <div id="product-desc">{parse(t(product.short_description))}</div>
        <Row className="overflow-hidden z-index d-flex justify-content-center">
          {product.product_variations
            .sort((a, b) => a.regular_price - b.regular_price)
            .map((variation) => {
              let hasStock;
              if (variation.stock === null) {
                hasStock = true;
              } else {
                if (variation.stock > 0) {
                  let cartProduct = cartProducts.find((i) => {
                    if (
                      i.product.variation &&
                      i.product.variation.id === variation.id
                    ) {
                      return true;
                    }
                    return false;
                  });

                  if (cartProduct) {
                    if (cartProduct.product.quantity < variation.stock) {
                      hasStock = true;
                    } else {
                      hasStock = false;
                    }
                  } else {
                    hasStock = true;
                  }
                } else {
                  hasStock = false;
                }
              }
              return (
                <Col
                  lg="6"
                  sm="6"
                  xs="6"
                  key={variation.id}
                  className={`mb-4`}
                  onClick={() => {
                    if (isModal) {
                      if (hasStock) {
                        setSelectedVariation(variation);
                        setShow(false);
                      }
                    } else {
                      if (hasStock) {
                        setSelectedProduct({
                          ...product,
                          variation: variation,
                          price: variation.regular_price,
                        });
                      }
                    }
                  }}
                >
                  <Card
                    role="button"
                    className={`${
                      selectedProduct.variation &&
                      variation.id === selectedProduct.variation.id
                        ? "border border-success"
                        : ""
                    } ${hasStock ? "" : "text-muted"}`}
                  >
                    {variation.images && (
                      <Card.Img
                        className="text-center"
                        alt="Card image cap"
                        src={variation.images[0].src}
                      />
                    )}
                    <Card.Body className="p-2">
                      <StyledProductTitle>
                        <span className="d-block">
                          {t(variation.name.replace(`${product.name} -`, ""))}
                        </span>
                      </StyledProductTitle>
                      <div className="text-center d-block">
                        <small>
                          {hasStock
                            ? variation.regular_price > 0
                              ? `${parseFloat(variation.regular_price).toFixed(
                                  2
                                )}€`
                              : "Seleccionar"
                            : "Agotado"}
                        </small>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
              );
            })}
          {!isModal && (
            <>
              {error && (
                <AlertComponent
                  variant="danger"
                  className="w-100 d-flex fixed-bottom m-auto"
                >
                  {error}
                </AlertComponent>
              )}
              <AddToCartButton
                originalProduct={product}
                product={selectedProduct}
                validator={variableProductValidator}
                setError={setError}
              />
            </>
          )}
        </Row>
      </Container>
    </>
  );
};
