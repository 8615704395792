import React, { useEffect, useState } from "react";
import { StyledLoadingOverlay, StyledIconWrapper } from "./style.css";
import { HiOutlineHome } from "react-icons/hi";
import { useTranslation } from "react-i18next";

export const LoadingComponent = ({ loadingMessages }) => {
  return (
    <StyledLoadingOverlay>
      <LoadingComponentIcon loadingMessages={loadingMessages} />
    </StyledLoadingOverlay>
  );
};

export const LoadingComponentIcon = ({ loadingMessages }) => {
  const [loadingText, setLoadingText] = useState("");
  const TEXT_INTERVAL_SECONDS = 3;

  const { t } = useTranslation();

  let loadingInterval = null;

  const loadingSteps = [
    "Decorando pasteles...",
    "Montando nata...",
    "Preparando bizcochos...",
    "Batiendo huevos...",
    "Tomando café...",
  ];

  function funLoading(messages) {
    let step = 0;
    setLoadingText(messages[step]);
    step++;
    loadingInterval = setInterval(() => {
      if (step < messages.length) {
        setLoadingText(messages[step]);
      }
      step++;
    }, TEXT_INTERVAL_SECONDS * 1000);
  }

  useEffect(() => {
    funLoading(loadingMessages ? loadingMessages : loadingSteps);
    return () => {
      clearInterval(loadingInterval);
    };
  }, []);

  return (
    <StyledIconWrapper>
      <div role={"img"}>
        <HiOutlineHome
          className="border border-1 p-3 border-dark rounded-circle"
          size={80}
        />
      </div>
      <span className={`small mt-3`}>{t(loadingText)}</span>
    </StyledIconWrapper>
  );
};
