import React, { useContext } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Context } from "../../Shared/Context/GlobalContext";
import { CartListComponent } from "../Orderpage/components/CardItemComponent";
import { CreateCustomerFormComponent } from "./components/CreateCustomerFormComponent";
import { ShipingCardComponent } from "./components/ShippingCardComponent";
import { ShippingFormComponent } from "./components/ShippingFormComponent";
import routes from "../../Helpers/Routes.json";
import { useTranslation } from "react-i18next";

function CheckoutPage() {
  const { user, cartProducts } = useContext(Context);
  const { t } = useTranslation();

  return (
    <Container>
      <h1 className="h1 text-center p-2">{t("Finalizar pedido")}</h1>
      <Row>
        <Col>
          {user ? (
            <>
              <ShipingCardComponent user={user} />
              {user.shipping.first_name ? (
                <Row>
                  <CartListComponent cartProducts={cartProducts} />
                  <Link
                    to={routes.Payment.index}
                    className="btn btn-lg btn-outline-success w-100"
                  >
                    {t("Ir al Pago")}
                  </Link>
                </Row>
              ) : (
                <ShippingFormComponent />
              )}
            </>
          ) : (
            <CreateCustomerFormComponent />
          )}
        </Col>
      </Row>
    </Container>
  );
}

export default CheckoutPage;
