import { Trans } from "react-i18next";

export function groupedProductValidator(product, cartProduct) {
  if (product.grouped_products.length === cartProduct.product.bundle.length) {
    return { success: true };
  }
  return {
    success: false,
    error: (
      <Trans
        i18nKey={`Faltan {{qty}} items por añadir`}
        values={{
          qty:
            product.grouped_products.length - cartProduct.product.bundle.length,
        }}
      />
    ),
  };
}

export function variableProductValidator(product, cartProduct) {
  if (product.variation.stock === 0) {
    return { success: false, error: <Trans i18nKey={`Opción agotada.`} /> };
  }

  if (cartProduct.product.variation) {
    return { success: true };
  }

  return { success: false, error: <Trans i18nKey={`Selecciona una opción`} /> };
}
