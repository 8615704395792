import { useContext, useEffect } from "react";
import { useNavigate } from "react-router";
import routes from "../../../../Helpers/Routes.json";
import { Context } from "../../../../Shared/Context/GlobalContext";

export let paymentInterval = null;

export const ProcessingPaymentComponent = ({
  transaction,
  setError,
  setTransaction,
}) => {
  const navigate = useNavigate();
  const { resetCart } = useContext(Context);

  async function processPaymentInterval(transaction) {
    paymentInterval = setTimeout(async () => {
      var response = await fetch(
        process.env.REACT_APP_API_HOST + `/api/payment/${transaction}/status`
      );

      if (response.status === 200) {
        clearTimeout(paymentInterval);
        const { data } = await response.json();

        switch (data.status) {
          case "paid":
            resetCart();
            navigate(
              routes.Payment.success.replace(":transaction", data.order)
            );
            break;
          case "error":
            setError(data.message);
            setTransaction(null);
            break;
          case "refunded":
            navigate(
              routes.Payment.declined.replace(":transaction", data.order)
            );
            break;
          default:
        }
      }
      await processPaymentInterval(transaction);
    }, 2000);
  }

  useEffect(() => {
    processPaymentInterval(transaction);
    return () => {
      clearTimeout(paymentInterval);
    };
  }, []);
  return null;
};
