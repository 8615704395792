export const useAuth = () => {
    async function login(user, passwd) {
        return fetch(process.env.REACT_APP_API_HOST + '/api/login', {
            'method': 'post',
            'body': JSON.stringify({
                'username': user,
                'password': passwd
            })
        })
            .catch(e => e)
            .then(res => res.json())
    }

    return { login }
}