import React from "react";
import { Button, Col, Form } from "react-bootstrap";
import { useInputValue } from "../../../../Shared/Hooks/useInputValue";

export const PaymentFormComponent = () => {
  const ccGivenName = useInputValue("", "ccname");
  const ccNumber = useInputValue("", "cardnumber");
  const ccExp = useInputValue("", "exp-date");
  const ccCSC = useInputValue("", "cvc");

  function handlePaymentSubmit(e) {
    e.preventDefault();
    /* const data = {
      number: ccNumber.value,
      givenName: ccGivenName.value,
      exp: ccExp.value,
      cvc: ccCSC.value,
    }; */
  }

  return (
    <Form className="row" method="post" onSubmit={handlePaymentSubmit}>
      <Col xs="12">
        <div className="d-flex flex-column">
          <Form.Label htmlFor="cc-name">Titular de la tarjeta:</Form.Label>
          <Form.Control
            className="p-2 mb-3"
            type="text"
            autoComplete="cc-name"
            id="cc-name"
            required
            {...ccGivenName}
          />
        </div>
      </Col>
      <Col xs="12">
        <div className="d-flex flex-column">
          <Form.Label htmlFor="cc-num">Tarjeta de crédito/débito:</Form.Label>
          <Form.Control
            className="p-2 mb-3"
            type="text"
            autoComplete="cc-number"
            id="cc-num"
            required
            {...ccNumber}
          />
        </div>
      </Col>
      <Col xs="6">
        <div className="d-flex flex-column">
          <Form.Label htmlFor="cc-exp">Expiración:</Form.Label>
          <Form.Control
            className="p-2 mb-3"
            type="month"
            autoComplete="cc-exp"
            placeholder="MM-YY"
            id="cc-exp"
            required
            {...ccExp}
          />
        </div>
      </Col>
      <Col xs="6">
        <div className="d-flex flex-column">
          <Form.Label htmlFor="cc-csc">CVV/CVC</Form.Label>
          <Form.Control
            className="p-2 mb-3 pt-2 "
            type="text"
            autoComplete="cc-csc"
            id="cc-csc"
            required
            {...ccCSC}
          />
        </div>
      </Col>
      <Col xs="12">
        <Button type="submit" variant="outline-success">
          Pagar
        </Button>
      </Col>
    </Form>
  );
};
