import React, { useContext, useState } from "react";
import {
  Alert,
  Button,
  Col,
  Container,
  Form,
  InputGroup,
  Row,
  Spinner,
} from "react-bootstrap";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { useNavigate } from "react-router";
import { Context } from "../../Shared/Context/GlobalContext";
import { useAuth } from "../../Shared/Hooks/useAuth";
import { useInputValue } from "../../Shared/Hooks/useInputValue";
import { CreateCustomerFormComponent } from "../CheckoutPage/components/CreateCustomerFormComponent";
import { Trans, useTranslation } from "react-i18next";
import routes from "../../Helpers/Routes.json";

function LoginPage({ doNavigate = true }) {
  const navigate = useNavigate();
  const username = useInputValue("", "username");
  const password = useInputValue("", "password");
  const [register, isRegister] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const auth = useAuth();
  const { setUser, user } = useContext(Context);
  const { t } = useTranslation();

  function handleLogin(e) {
    e.preventDefault();
    setLoading(true);
    setError(null);
    auth
      .login(username.value, password.value)
      .catch(() => {
        setLoading(false);
      })
      .then(({ data, code }) => {
        setLoading(false);
        if (200 === code) {
          setError(null);
          setUser(data);
          if (doNavigate) {
            navigate("/");
          }
        } else {
          setError(true);
        }
      });
  }

  if (user) {
    return navigate(routes.AccountPage.index);
  }

  if (register) {
    return (
      <Container className="pb-5 mb-5">
        <CreateCustomerFormComponent isRegister={isRegister} />
      </Container>
    );
  }

  return (
    <Container>
      <Row>
        <h1>{t("Iniciar sesión")}</h1>
        {error && (
          <Row>
            <Col>
              <Alert variant="danger">
                {t("Usuario o contraseña incorrectos.")}
              </Alert>
            </Col>
          </Row>
        )}
        <Col>
          <Form method="POST" onSubmit={handleLogin} className="mb-2">
            <Form.Group className="mt-2">
              <Form.Label required htmlFor="username">
                {t("Nombre de usuario")}
              </Form.Label>
              <Form.Control {...username} isInvalid={error} />
            </Form.Group>
            <Form.Group className="mt-3">
              <Form.Label>{t("Contraseña")}</Form.Label>
              <InputGroup>
                <Form.Control
                  required
                  isInvalid={error}
                  {...password}
                  type={`${showPassword ? "text" : "password"}`}
                />
                <InputGroup.Text
                  className="bg-light"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? <AiOutlineEye /> : <AiOutlineEyeInvisible />}
                </InputGroup.Text>
              </InputGroup>
            </Form.Group>
            <Button
              variant="outline-success"
              type="submit"
              className="d-flex align-items-center mt-4"
              disabled={loading}
            >
              {loading && (
                <Spinner
                  animation="border"
                  role={"status"}
                  style={{ marginRight: "10px" }}
                />
              )}
              <div className="d-block">{t("Iniciar sesion")}</div>
            </Button>
          </Form>
          <small className="font-italic">
            <a
              target="__new"
              href="https://lacasablancapastisseria.com/mi-cuenta/lost-password/"
            >
              {t("¿Has olvidado la contraseña?")}
            </a>
          </small>
          <hr />
          <Trans
            i18nKey={
              "<Small>¿No tienes una cuenta?<LoginLink>Registrate aquí</LoginLink></Small>"
            }
            components={{
              Small: <small />,
              LoginLink: (
                <strong
                  role={"button"}
                  className="btn btn-sm btn-outline-success"
                  onClick={() => {
                    isRegister(true);
                  }}
                />
              ),
            }}
          />
        </Col>
      </Row>
    </Container>
  );
}

export default LoginPage;
