import React, { useContext, useEffect } from "react";
import routes from "../../../../Helpers/Routes.json";
import { Context } from "../../../../Shared/Context/GlobalContext";
import { StyledCategoriesGrid, StyledCategory } from "./style.css";
import { useTranslation } from "react-i18next";

const ProdutCategories = ({ setSelectedCategory, categories }) => {
  const { firstRender, isFirstRender } = useContext(Context);
  const { t } = useTranslation();

  useEffect(() => {
    return () => {
      isFirstRender(false);
    };
  }, []);

  return (
    <StyledCategoriesGrid className="w-100">
      {categories
        .sort((a, b) => {
          if (a.menu_order < b.menu_order) {
            return -1;
          } else if (a.menu_order === b.menu_order) {
            return 0;
          }

          return 1;
        })
        .map((cat) => {
          const img = cat.image ? cat.image : {};

          return (
            <StyledCategory
              key={cat.id}
              role="button"
              onClick={() => setSelectedCategory(cat)}
              to={routes.Homepage.category.replace(":category", cat.slug)}
              animate={firstRender ? "true" : "false"}
            >
              <div className="d-flex justify-content-center align-items-center">
                <img src={img.src} alt={img.alt} />
              </div>
              <span>{t(cat.name)}</span>
            </StyledCategory>
          );
        })}
    </StyledCategoriesGrid>
  );
};

export default React.memo(ProdutCategories);
