import React, { useContext, useState } from "react";
import { Button, Container, Form, Row, Spinner } from "react-bootstrap";
import { Woo } from "../../../../Helpers/Woo";
import { Context } from "../../../../Shared/Context/GlobalContext";
import { useInputValue } from "../../../../Shared/Hooks/useInputValue";

export const ShippingFormComponent = () => {
  const { user, setUser } = useContext(Context);

  const name = useInputValue("", "name");
  const surname = useInputValue("", "surname");
  const phone = useInputValue("", "phone");
  const address = useInputValue("", "address");
  const country = useInputValue("", "country");
  const postalcode = useInputValue("", "postalcode");
  const city = useInputValue("", "city");
  const [loading, setLoading] = useState(false);

  function handleShippingSubmit(e) {
    e.preventDefault();
    setLoading(true);
    Woo.customers
      .put(user.id, {
        first_name: name.value,
        last_name: surname.value,
        billing: {
          first_name: name.value,
          last_name: surname.value,
          address_1: address.value,
          city: city.value,
          postcode: postalcode.value,
          country: country.value,
          phone: phone.value,
        },
        shipping: {
          first_name: name.value,
          last_name: surname.value,
          address_1: address.value,
          city: city.value,
          postcode: postalcode.value,
          country: country.value,
          phone: phone.value,
        },
      })
      .then((res) => {
        setUser(res.data);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  return (
    <Container className="mb-5 pb-5 mt-3">
      <h1>Direccion de facturación</h1>
      <Form onSubmit={handleShippingSubmit}>
        <Row>
          <Form.Group className="mb-3 col">
            <Form.Label>Nombre</Form.Label>
            <Form.Control required {...name} size="lg" autoComplete="name" />
          </Form.Group>
          <Form.Group className="mb-3 col">
            <Form.Label>Apellidos</Form.Label>
            <Form.Control size="lg" {...surname} autoComplete="family-name" />
          </Form.Group>
        </Row>
        <Form.Group className="mb-3">
          <Form.Label>Pais</Form.Label>
          <Form.Control
            size="lg"
            {...country}
            autoComplete="country"
            value={"España"}
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Ciudad</Form.Label>
          <Form.Control size="lg" {...city} autoComplete="city" />
        </Form.Group>
        <Button type="submit" disabled={loading} variant="outline-success">
          {loading ? (
            <Spinner animation="border" size="sm" className="me-2"></Spinner>
          ) : null}
          Continuar
        </Button>
      </Form>
    </Container>
  );
};
