import React, { useContext, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router";
import { LoadingComponentIcon } from "../../../Components/Loading";
import { ModalComponent } from "../../../Components/Modal";
import routes from "../../../Helpers/Routes.json";
import { Context } from "../../../Shared/Context/GlobalContext";
import { useTranslation } from "react-i18next";

export const CashExternalButtonComponent = ({
  id,
  description,
  name,
  shippingMethod,
  deliveryAt,
  validateOrder,
  cartProducts,
  user,
  comments,
  method,
  couponCode,
}) => {
  const navigate = useNavigate();
  const { resetCart } = useContext(Context);
  const [submited, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  return (
    <Col xs="12" className="mt-3 text-center">
      <Button
        size="xl"
        variant="outline-success"
        className="p-3 rounded rounded-pill w-100"
        data-method={id}
        title={description}
        onClick={(e) => {
          const validationResult = validateOrder();

          switch (validationResult) {
            case "missing_shipping_method":
              alert("Falta seleccionar forma de entrega");
              break;

            case "missing_shipping_delivery_at":
              alert("Selecciona una fecha de entrega");
              break;
            default:
              setSubmitted(true);
              break;
          }
        }}
      >
        {name}
      </Button>
      <ModalComponent
        show={submited}
        setShow={setSubmitted}
        title={t("Pagar en tienda")}
      >
        {loading && (
          <Row>
            <Col>
              <LoadingComponentIcon loadingMessages={["Procesando Pedido"]} />
            </Col>
          </Row>
        )}
        <Row>
          <Col>
            {
              "Si decides pagar en tienda te reservaremos el pedido durante 2 días, si durante este tiempo no tenemos noticias lo cancelaremos. ¿Quieres continuar?"
            }
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>
            <Button
              variant="success"
              className="w-100 rounded-pill"
              disabled={loading}
              onClick={(e) => {
                setLoading(true);
                fetch(process.env.REACT_APP_API_HOST + "/api/payment", {
                  method: "post",
                  headers: {
                    "X-Customer-Token": user.access_token,
                  },
                  body: JSON.stringify({
                    products: cartProducts,
                    payment_method: String(id),
                    shipping_method: shippingMethod,
                    coupon_code: couponCode,
                    delivery_at: deliveryAt,
                    comments: comments,
                  }),
                })
                  .then((res) => {
                    setLoading(false);
                    if (res.status === 200) {
                      return res.json();
                    }
                    throw new Error("Something went wrong");
                  })
                  .catch((error) => {
                    console.error(error);
                  })
                  .then(({ data }) => {
                    setSubmitted(false);
                    setLoading(false);

                    resetCart();
                    navigate(
                      routes.Payment.success.replace(
                        ":transaction",
                        data.transaction
                      )
                    );
                  });
              }}
            >
              {t("Si")}
            </Button>
          </Col>
          <Col>
            <Button
              variant="outline-secondary"
              className="w-100 rounded-pill"
              disabled={loading}
              onClick={() => setSubmitted(false)}
            >
              {t("No")}
            </Button>
          </Col>
        </Row>
      </ModalComponent>
    </Col>
  );
};
