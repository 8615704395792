import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { LoadingComponent } from "../../../Components/Loading";
import { ModalComponent } from "../../../Components/Modal";
import { ProcessingPaymentComponent } from "./PaymentComponents/ProcessingPaymentComponent";
import { useTranslation } from "react-i18next";

export const RedsysRedirectionButtonComponent = ({
  id,
  description,
  name,
  shippingMethod,
  deliveryAt,
  validateOrder,
  cartProducts,
  user,
  callback,
  comments,
  couponCode,
  method,
}) => {
  const redsysRef = useRef(null);
  const [show, setShow] = useState(false);
  const [transaction, setTransaction] = useState(null);
  const [loading, setLoading] = useState(true);
  const [backdrop, setBackdrop] = useState("static");
  const [wooOrder, setWooOrder] = useState(null);
  const [error, setError] = useState("hola");

  const { t } = useTranslation();

  async function initRedsys() {
    setLoading(true);
    setError(null);
    fetch(process.env.REACT_APP_API_HOST + "/api/payment", {
      method: "post",
      headers: {
        "X-Customer-Token": user.access_token,
      },
      body: JSON.stringify({
        products: cartProducts,
        payment_method: String(id),
        shipping_method: shippingMethod,
        delivery_at: deliveryAt,
        coupon_code: couponCode,
        comments: comments,
        twc_woo_order_id: wooOrder,
      }),
    })
      .then((res) => {
        if (res.status === 200) {
          return res.json();
        }
        throw new Error("Something went wrong");
      })
      .catch((error) => {
        console.error(error);
      })
      .then(async ({ data }) => {
        const redsysFormElement = redsysRef.current;
        redsysFormElement.action = data.url;
        redsysFormElement.querySelector(
          'input[name="Ds_SignatureVersion"]'
        ).value = data.signature_version;
        redsysFormElement.querySelector('input[name="Ds_Signature"]').value =
          data.signature;
        redsysFormElement.querySelector(
          'input[name="Ds_MerchantParameters"]'
        ).value = data.merchant_parameters;
        redsysFormElement.submit();
        setTransaction(data.transaction);
        setWooOrder(data.twc_woo_order_id);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  return (
    <>
      <Col xs="12" className="mt-3 text-center">
        <Button
          size="xl"
          variant="outline-success p-3 rounded rounded-pill w-100"
          data-method={id}
          title={description}
          onClick={async (e) => {
            const validationResult = validateOrder();

            switch (validationResult) {
              case "missing_shipping_method":
                alert("Falta seleccionar forma de entrega");
                break;

              case "missing_shipping_delivery_at":
                alert("Selecciona una fecha de entrega");
                break;
              default:
                setShow(true);
                setError(null);
                await initRedsys();
                break;
            }
          }}
        >
          {name}
        </Button>
      </Col>
      <RedsysFormComponent forwardRef={redsysRef} />
      <ModalComponent
        title={t("Completa tu pago")}
        show={show}
        setShow={setShow}
        callback={() => {
          setTransaction(null);
          setBackdrop("static");
        }}
        modalProps={{
          modal: {
            backdrop: backdrop,
          },
        }}
      >
        <Row>
          <Col className="ratio" style={{ "--bs-aspect-ratio": "170%" }}>
            {loading && (
              <LoadingComponent
                loadingMessages={[
                  t("Cargando la pasarela de pago, por favor espera..."),
                  t("Seguimos esperando al banco..."),
                  t("Sabemos que quieres algo dulce, espera un poquito mas..."),
                ]}
              />
            )}
            {error ? (
              <Col className="text-center">
                <h2 className="mb-4 fw-bold">
                  ⚠️ El pago ha sido rechazado ⚠️
                </h2>
                <h5 className="mb-4">{error}</h5>
                <Button
                  onClick={async () => {
                    await initRedsys();
                  }}
                >
                  Volver a intentarlo
                </Button>
              </Col>
            ) : (
              <iframe
                title="Redsys Payment"
                name="redsys-redirection-iframe"
                className="w-100 h-100"
                onLoad={() => {
                  setLoading(false);
                  setBackdrop(true);
                }}
              ></iframe>
            )}
          </Col>
        </Row>
      </ModalComponent>
      {transaction ? (
        <ProcessingPaymentComponent
          transaction={transaction}
          setError={setError}
          setTransaction={setTransaction}
        />
      ) : null}
    </>
  );
};

const RedsysFormComponent = ({ forwardRef }) => {
  return (
    <form
      ref={forwardRef}
      target="redsys-redirection-iframe"
      name="from"
      action=""
      method="POST"
      style={{ display: "none", opacity: "0" }}
    >
      <input type="hidden" name="Ds_SignatureVersion" value="" />
      <input type="hidden" name="Ds_MerchantParameters" value="" />
      <input type="hidden" name="Ds_Signature" value="" />
    </form>
  );
};
