import React, { useContext } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router";
import { Context } from "../../Shared/Context/GlobalContext";
import { Link } from "react-router-dom";
import routes from "../../Helpers/Routes.json";
import { useTranslation } from "react-i18next";

export default function AccountPage() {
  const { user, logout } = useContext(Context);
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <Container>
      <Row className="text-center">
        <Col xs="12" className="text-center mb-4">
          <img
            src={user.avatar_url}
            alt="profile"
            width={100}
            height={100}
            className="rounded-circle mt-5"
          />
          <h3 className="h3 mt-1 mb-0">{user.username}</h3>
          <small>{user.email}</small>
        </Col>
        <Col>
          <a
            href="https://lacasablancapastisseria.com/mi-cuenta/"
            target={"_blank"}
            rel={"noreferrer"}
            variant={"outline-secondary"}
            className="btn btn-outline-secondary w-100 mt-3"
          >
            {t("Editar mi cuenta")}
          </a>
          <Link
            as="btn"
            variant={"outline-secondary"}
            className="w-100 mt-3 btn btn-block btn-outline-secondary"
            to={routes.OrdersPage.index}
          >
            {t("Mis pedidos")}
          </Link>
          {/* <Button
            variant={"outline-secondary"}
            className="w-100 mt-3"
          >
            Cambiar Idioma
          </Button> */}
          <Button
            variant={"outline-success"}
            className="w-100 mt-3"
            onClick={() => navigate(-1)}
          >
            {t("Volver")}
          </Button>
          <Button
            size="sm"
            variant={"outline-danger"}
            className="w-100 mt-3"
            onClick={() => {
              if (window.confirm(t("¿Seguro que quieres cerrar sesión?"))) {
                logout();
                navigate(routes.Homepage.index);
              }
            }}
          >
            {t("Cerrar sesión")}
          </Button>
        </Col>
      </Row>
    </Container>
  );
}
