import { useEffect, useState } from "react";

export const usePaymentMethods = (initialValue) => {
  const [paymentMethods, setPaymentMethods] = useState(initialValue);

  useEffect(() => {
    fetch(process.env.REACT_APP_API_HOST + "/api/payment-methods", {
      method: "get",
    })
      .then((res) => res.json())
      .then((data) => setPaymentMethods(data.data));
  }, []);

  return { paymentMethods };
};
